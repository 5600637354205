import { DeductibleForTariff } from '@getpopsure/private-health-insurance-pricing-engine';

export type ShortTermFreelancerDeductible =
  DeductibleForTariff<'HiMedicalPlus'>;
export type LongTermFreelancerDeductible = Exclude<
  DeductibleForTariff<'NKSelectL' | 'NKSelectS' | 'NKSelectXL'>,
  '600'
>;

export type FreelancerDeductibleType =
  | ShortTermFreelancerDeductible
  | LongTermFreelancerDeductible;

export const shortTermDeductibleMapper: Record<
  ShortTermFreelancerDeductible,
  string
> = {
  '500': '10%, up to €500',
  '1200': '€1200',
};
export const shortTermDeductibleOptions = Object.values(
  shortTermDeductibleMapper
);

export const longTermDeductibleMapper: Record<
  LongTermFreelancerDeductible,
  string
> = {
  '0': '€0',
  '1200': '€1200',
  '3000': '€3000',
};
export const longtTermDeductibleOptions = Object.values(
  longTermDeductibleMapper
);
