import { blog, calendly } from '@getpopsure/private-constants';
import {
  defaultAddonsLookup,
  getDefaultSickDayPayout,
  getPriceBreakdown,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { toast } from '@popsure/dirty-swan';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { EmailAddressCollectionModal } from 'components/EmailAddressCollectionModal';
import FAQSection from 'components/faq';
import MoreQuestionsSection from 'components/moreQuestions';
import ReviewBadge from 'components/reviewBadge';
import SectionHeader from 'components/sectionHeader';
import {
  persistPrivatePreSignupQuestionnaire,
  PrivatePreSignupDispatch,
} from 'features/privateHealthPreSignup/actions';
import { TableModal } from 'features/privateHealthPreSignup/components/TableModal';
import {
  Modal,
  planOptions,
  PrivatePreSignup,
  Tariff,
  tariffLabelMapper,
} from 'features/privateHealthPreSignup/models';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { APIResponseError } from 'models/error';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatErrorMessageFromError } from 'selectors/requests';
import { Trans, useSafeTranslation } from 'shared/i18n';
import { sleep } from 'shared/util/sleep';

import { Coverage } from './Coverage';
import {
  LongTermFreelancerDeductible,
  ShortTermFreelancerDeductible,
} from './Coverage/models';
import { faqContent } from './faqContent';
import { FrozenFooter } from './FrozenFooter/FrozenFooter';
import { Header } from './Header';
import { IncomeProtection } from './IncomeProtection/IncomeProtection';
import { InfoCard } from './InfoCard/InfoCard';
import { PrivateDescriptionModal } from './PrivateDescriptionModal';
import styles from './Quote.module.scss';
import { WhyFeather } from './WhyFeather/WhyFeather';

export const Quote = ({
  questionnaireAnswers,
  onSubmitValue,
}: CustomComponentProps<PrivatePreSignup>) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<PrivatePreSignupDispatch>();

  const {
    birthYear = 1900,
    income = 0,
    employmentStatus = 'OTHER',
    quote,
  } = questionnaireAnswers;

  const defaultSickDayPayout = getDefaultSickDayPayout(income);

  const [sickDayPayout, setSickDayPayout] = useState(
    quote?.sickDayPayout ?? defaultSickDayPayout
  );

  const eligibleForShortTermFromURL =
    useQueryParamValue('eligibleForShortTerm') || '';

  useEffect(() => {
    if (
      ['true', 'false'].includes(eligibleForShortTermFromURL) &&
      (eligibleForShortTermFromURL === 'true') !==
        questionnaireAnswers.eligibleForShortTerm
    ) {
      dispatch(
        storeGenericQuestionnaireAnswer('privateHealthPreSignup', {
          eligibleForShortTerm: eligibleForShortTermFromURL === 'true',
        })
      );
    }
  }, [
    eligibleForShortTermFromURL,
    questionnaireAnswers.eligibleForShortTerm,
    dispatch,
  ]);

  const coverageSectionRef = useRef<HTMLDivElement>(null);
  const coverageSectionIntersection = useIntersectionObserver(
    coverageSectionRef,
    { rootMargin: '0px 0px -100%' }
  );

  const [modal, setModal] = useState<null | Modal>();
  const [bookACallModal, setBookACallModal] = useState(false);
  const [sendQuoteModal, setSendQuoteModal] = useState(false);

  const showHeaderPrice = !!coverageSectionIntersection?.isIntersecting;

  const { eligibleForShortTerm } = questionnaireAnswers;

  const planFromRedux = questionnaireAnswers.quote?.tariff;

  const fallbackPlan: Tariff = 'NKSelectS';

  const [selectedPlan, setSelectedPlan] = useState<Tariff>(
    planFromRedux && planOptions.includes(planFromRedux)
      ? planFromRedux
      : fallbackPlan
  );

  const [shortTermDeductible, setShortTermDeductible] =
    useState<ShortTermFreelancerDeductible>('500');
  const [longTermDeductible, setLongTermDeductible] =
    useState<LongTermFreelancerDeductible>('0');

  const isSelectedPlanShortTerm = selectedPlan === 'HiMedicalPlus';
  const selectedPlanDeductible = isSelectedPlanShortTerm
    ? shortTermDeductible
    : longTermDeductible;

  const handleBookACallModal = () => setBookACallModal(true);

  const onOpenShortTermInfoModal = () =>
    setModal({
      title: t(
        'private.qnr.quote.shortTermCard.modal.title',
        'What is the short-term coverage?'
      ),
      children: <PrivateDescriptionModal />,
    });

  const handleSendQuoteModal = () => setSendQuoteModal(true);

  const handleSubmit = () =>
    onSubmitValue({
      tariff: selectedPlan,
      deductible: selectedPlanDeductible,
      selectedAddOns: defaultAddonsLookup[selectedPlan ?? fallbackPlan],
      sickDayPayout,
    });

  const priceBreakdown = getPriceBreakdown({
    tariff: selectedPlan ?? fallbackPlan,
    birthYear,
    sickDayPayout,
    employmentStatus,
    deductible: selectedPlanDeductible,
  });

  const qnrShortTermPrice = eligibleForShortTerm
    ? getPriceBreakdown({
        tariff: 'HiMedicalPlus',
        birthYear,
        sickDayPayout,
        employmentStatus,
        deductible: shortTermDeductible,
      }).contributions.own
    : undefined;

  const qnrBasicPrice = getPriceBreakdown({
    tariff: 'NKSelectS',
    birthYear,
    sickDayPayout,
    employmentStatus,
    deductible: longTermDeductible,
  }).contributions.own;

  const qnrPlusPrice = getPriceBreakdown({
    tariff: 'NKSelectL',
    birthYear,
    sickDayPayout,
    employmentStatus,
    deductible: longTermDeductible,
  }).contributions.own;

  const qnrPremiumPrice = getPriceBreakdown({
    tariff: 'NKSelectXL',
    birthYear,
    sickDayPayout,
    employmentStatus,
    deductible: longTermDeductible,
  }).contributions.own;

  return (
    <>
      <EmailAddressCollectionModal
        title={t('private.qnr.quote.sendQuoteModal.title', 'Send me my quote')}
        description={t(
          'private.qnr.quote.sendQuoteModal.description',
          'We will also be able to locate your quote if you reach out for expert advice. No unsolicited emails.'
        )}
        buttonCaption={t(
          'private.qnr.quote.sendQuoteModal.buttonCaption',
          'Send'
        )}
        showMailIcon
        isOpen={sendQuoteModal}
        onClose={() => setSendQuoteModal(false)}
        onSubmit={async ({ setSubmitting, setError, name, email }) => {
          setError('');
          setSubmitting(true);
          const { status, hashOfQuestionnaire, error } = await dispatch(
            persistPrivatePreSignupQuestionnaire({
              answers: questionnaireAnswers,
              shortTermPrice: qnrShortTermPrice,
              basicPrice: qnrBasicPrice,
              plusPrice: qnrPlusPrice,
              premiumPrice: qnrPremiumPrice,
              email,
              name,
              sendEmail: true,
            })
          );
          if (status === 'SUCCESS') {
            await sleep(500);
            toast('Your quote has been sent', { type: 'success' });
            dispatch(
              storeGenericQuestionnaireAnswer('privateHealthPreSignup', {
                hashOfQuestionnaire,
              })
            );
            setSubmitting(false);
            setSendQuoteModal(false);
          } else {
            setError(formatErrorMessageFromError(error as APIResponseError));
            setSubmitting(false);
          }
        }}
      />
      <div className="bg-white pb80">
        <Header
          onSendQuoteButtonClick={handleSendQuoteModal}
          onTalkToExpertButtonClick={handleBookACallModal}
        />
        {eligibleForShortTerm && (
          <InfoCard className="mt24 wmx8">
            <h4 className="p-h4 ta-left">
              {t(
                'private.qnr.quote.shortTermInfo.title',
                'You have access to our short-term plan'
              )}
            </h4>
            <Trans i18nKey="private.qnr.quote.shortTermInfo.description">
              <p className="p-p tc-grey-600 mt8 ta-left">
                The short-term plan you'll see below excludes old-age
                contributions and is available during the first 5 years in
                Germany.{' '}
                <button
                  className="ds-interactive-component p-a c-pointer fw-bold"
                  onClick={onOpenShortTermInfoModal}
                  type="button"
                >
                  Learn more
                </button>
              </p>
            </Trans>
          </InfoCard>
        )}
        <section className="mt24" ref={coverageSectionRef}>
          <Coverage
            eligibleForShortTerm={eligibleForShortTerm}
            birthYear={birthYear}
            income={income}
            sickDayPayout={sickDayPayout}
            employmentStatus={employmentStatus}
            showHeaderPrice={showHeaderPrice}
            selectedPlan={selectedPlan}
            setSelectedPlan={(plan) => setSelectedPlan(plan)}
            shortTermDeductible={shortTermDeductible}
            longTermDeductible={longTermDeductible}
            setShortTermDeductible={setShortTermDeductible}
            setLongTermDeductible={setLongTermDeductible}
          />
        </section>
        <section className="p-container mt80">
          <SectionHeader title="Customize" centeredOnMobile />
          <IncomeProtection
            sickDayPayout={sickDayPayout}
            handleSliderChange={(n) => setSickDayPayout(n)}
            income={income}
          />
        </section>
        <section className="p-container mt96">
          <SectionHeader title="Why Feather" />
          <WhyFeather />
        </section>
        <section className="p-body mt96">
          <FAQSection
            markdownClassName={styles.markdown}
            data={faqContent(eligibleForShortTerm)}
            title="Frequently asked questions"
          />
        </section>
        <section className="p-container d-flex mt96">
          <ReviewBadge className="m-auto" />
        </section>
      </div>
      <EmailAddressCollectionModal
        isOpen={bookACallModal}
        onClose={() => setBookACallModal(false)}
        onSubmit={async ({ setSubmitting, setError, name, email }) => {
          setError('');
          setSubmitting(true);
          const { status, error, questionnaireId } = await dispatch(
            persistPrivatePreSignupQuestionnaire({
              answers: questionnaireAnswers,
              shortTermPrice: qnrShortTermPrice,
              basicPrice: qnrBasicPrice,
              premiumPrice: qnrPremiumPrice,
              email,
              name,
              hashOfLastSubmittedQuestionnaire:
                questionnaireAnswers.hashOfQuestionnaire,
            })
          );
          if (status === 'SUCCESS') {
            setBookACallModal(false);
            document.body.style.overflow = 'auto';
            window.Calendly.initPopupWidget({
              url: calendly.healthInsuranceAdvicePriority,
              prefill: {
                name,
                email,
                customAnswers: {
                  a4: questionnaireId,
                },
              },
            });
          } else {
            setError(formatErrorMessageFromError(error as APIResponseError));
          }
          setSubmitting(false);
        }}
      />
      <MoreQuestionsSection
        bookACallGACategory="PRIVATE_HEALTH_INSURANCE"
        description="Reach out to us for more information or check out our Insurance to better understand private health insurance."
        secondaryAction="Read the Insurance Guide"
        bookACallAction={handleBookACallModal}
        secondaryActionLink={blog.healthInsuranceGuide}
        openInNewTab
      />
      {modal && <TableModal modal={modal} setModal={setModal} />}
      {!!selectedPlan && (
        <FrozenFooter
          caption={`${tariffLabelMapper[selectedPlan]} plan`}
          price={Math.round(priceBreakdown.contributions.own)}
          onContinueClick={handleSubmit}
        />
      )}
    </>
  );
};
