import { TallyModal } from 'components/TallyModal';
import dayjs from 'dayjs';
import { PolicySingleModalProps } from 'features/policySingle/components/modals';
import { useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';

export const PrivateHealthCancelPolicyModal = (
  props: PolicySingleModalProps
) => {
  const {
    onClose,
    policyData: {
      attributes: { insuredPerson, planName, policyNumber },
    },
  } = props;
  const account = useSelector(getAccountInfo);

  const URLparams = {
    firstname: insuredPerson?.name?.firstName,
    lastname: insuredPerson?.name?.lastName,
    email: account?.email,
    policy_number: policyNumber,
    tariff_type: planName?.includes('HiMedical') ? 'shortTerm' : 'longTerm',
    dob: account?.dateOfBirth
      ? dayjs(account?.dateOfBirth).format('DD.MM.YYYY')
      : '',
  };

  return (
    <TallyModal
      isVisible
      onClose={onClose}
      tallyId="mKGLA7"
      title="Private Health Insurance Cancellation"
      URLparams={URLparams}
    />
  );
};
