import {
  EmploymentStatus,
  tariffNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import {
  ActivityIcon,
  DentalToothTeethIcon,
  DependentsFamilyIcon,
  EyeVisionIcon,
  GlobeNetworkEarthIcon,
  HeartIcon,
  HospitalBuildingIcon,
  TableHeader,
  TableRowHeader,
} from '@popsure/dirty-swan';
import dayjs from 'dayjs';
import {
  deductibleInfoMapping,
  ModalWithTracking,
  TableData,
  Tariff,
  TariffsWithPublic,
} from 'features/privateHealthPreSignup/models';

import {
  renderBoolean,
  renderCashback,
  renderContribution,
  renderDeductibleAdjustment,
  renderSickPayCoverage,
  renderStringOrBoolean,
  renderTableCTA,
  renderValueWithDescription,
  withInfoModal,
} from './tableRenderers';

const currentYear = dayjs().year();

export const getTableHeaders = ({
  setModal,
  handleOpenContributionModal,
  showPrice,
  selectedPlan,
  setSelectedPlan,
  handleCashbackModal,
  handleDeductibleAdjustmentModal,
}: {
  setModal: (modal: ModalWithTracking) => void;
  handleOpenContributionModal: (tariff: TariffsWithPublic) => void;
  showPrice: boolean;
  selectedPlan?: Tariff;
  setSelectedPlan: (tariff: Tariff) => void;
  handleCashbackModal: (tariff: Tariff) => void;
  handleDeductibleAdjustmentModal: (tariff: Tariff) => void;
}) => {
  const headers: Array<TableHeader<TableData>> = [
    {
      id: 0,
      label: '',
      default: true,
      cells: [
        {
          key: 'name',
          label: 'Choose a plan',
          render: renderTableCTA({ selectedPlan, setSelectedPlan, showPrice }),
        },
        {
          key: 'contribution',
          label: 'Your contribution',
          render: renderContribution(handleOpenContributionModal),
        },
        {
          key: 'cashback' as keyof TableData,
          label: 'Cashback',
          render: renderCashback(handleCashbackModal),
        },
        {
          key: 'dependents',
          label: 'Dependents coverage',
          render: withInfoModal(
            renderStringOrBoolean,
            'dependentsInfo',
            setModal,
            'Dependents coverage'
          ),
        },
        {
          key: 'deductible',
          label: (
            <TableRowHeader
              label="Deductible"
              onClickInfo={() =>
                setModal({
                  fieldKey: 'deductible',
                  tariff: 'header',
                  title: 'What is a deductible?',
                  children: (
                    <div className="p-p">
                      <p className="mt16">
                        A deductible is the amount you need to pay for
                        healthcare services on your own before your health
                        insurance begins to pay. It resets every calendar year.
                      </p>
                      <p className="mt16 fw-bold">
                        You don’t have to pay a deductible for many preventative
                        services like dental check-ups and vaccines.
                      </p>
                      <p className="mt16">
                        For example, if your yearly deductible is{' '}
                        {englishFormattedEuroCurrency(600, true)} and you have
                        healthcare bills for a total of{' '}
                        {englishFormattedEuroCurrency(1_000, true)}, you'll pay{' '}
                        {englishFormattedEuroCurrency(600, true)} and the
                        insurance will cover the rest — up to any coverage
                        limits.
                      </p>
                    </div>
                  ),
                })
              }
            />
          ),
          render: renderDeductibleAdjustment(handleDeductibleAdjustmentModal),
        },
      ],
    },
    {
      id: 1,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <HeartIcon size={20} noMargin /> General
        </p>
      ),
      cells: [
        {
          key: 'generalDoctor',
          label: (
            <TableRowHeader
              label="General doctors' visits"
              onClickInfo={() =>
                setModal({
                  fieldKey: 'generalDoctor',
                  tariff: 'header',
                  title: "General doctors' visits",
                  children:
                    'E.g. General practitioner, eye doctor,  gynecologist, pediatrician, emergency doctor',
                })
              }
            />
          ),
          render: renderStringOrBoolean,
        },
        {
          key: 'specialist',
          label: (
            <TableRowHeader
              label="Specialists"
              onClickInfo={() =>
                setModal({
                  fieldKey: 'specialist',
                  tariff: 'header',
                  title: 'Specialists',
                  children:
                    'E.g. dermatologist, orthopedic doctor, ear-nose-throat doctor',
                })
              }
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'specialistInfo',
            setModal,
            'Specialists'
          ),
        },
        {
          key: 'medication',
          label: <TableRowHeader label="Medication" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'medicationInfo',
            setModal,
            'Medication'
          ),
        },
        {
          key: 'vaccination',
          label: <TableRowHeader label="Vaccinations" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'vaccinationInfo',
            setModal,
            'Vaccinations'
          ),
        },
        {
          key: 'transports',
          label: (
            <TableRowHeader
              label="Transportation"
              onClickInfo={() =>
                setModal({
                  fieldKey: 'transports',
                  tariff: 'header',
                  title: 'Transportation',
                  children:
                    'Covered in the event of an emergency: ambulance, taxi, or Uber to and from the closest suitable hospital or clinic.',
                })
              }
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'transportsInfo',
            setModal,
            'Transportation'
          ),
        },
        {
          key: 'sickPayCoverage',
          label: (
            <TableRowHeader
              label="Income protection"
              onClickInfo={() =>
                setModal({
                  fieldKey: 'sickPayCoverage',
                  tariff: 'header',
                  title: 'Income protection',
                  children:
                    "The income protection kicks in if you're unable to work due to a sickness that lasts longer than 42 days. It is added by default on public insurance if you’re employed, and is especially important for freelancers since it can cover the cost of living if you get sick. You can cover up to 100% of your net income.\n\n The payout amount can be adjusted when signing up.",
                })
              }
            />
          ),
          render: renderSickPayCoverage,
        },
        {
          key: 'digitalHealthApps',
          label: <TableRowHeader label="Digital health apps" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'digitalHealthAppsInfo',
            setModal,
            'Digital health apps'
          ),
        },
        {
          key: 'nonMedicalPrescriptions',
          label: (
            <TableRowHeader
              label="Non-medical prescriptions"
              subtitle="E.g. contraceptives, supplements"
            />
          ),
          render: renderStringOrBoolean,
        },
        {
          key: 'purelyCosmeticProcedures',
          label: (
            <TableRowHeader
              label="Purely cosmetic procedures"
              subtitle="E.g. tattoo removal, botox, liposuction, facelift"
            />
          ),
          render: renderStringOrBoolean,
        },
      ],
    },
    {
      id: 2,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <DentalToothTeethIcon size={20} noMargin /> Dental
        </p>
      ),
      cells: [
        {
          key: 'annualCoverage',
          label: (
            <TableRowHeader
              label="Annual coverage limit"
              subtitle="For all dental treatments"
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'annualCoverageInfo',
            setModal,
            'Annual coverage limit'
          ),
        },
        {
          key: 'dentalCheckups',
          label: <TableRowHeader label="Dental checkups" />,
          render: renderStringOrBoolean,
        },
        {
          key: 'dentalCleanings',
          label: (
            <TableRowHeader label="Dental cleanings" subtitle="Prophylaxis" />
          ),
          render: renderValueWithDescription,
        },
        {
          key: 'dentist',
          label: (
            <TableRowHeader
              label="Dental procedures"
              subtitle="E.g. fillings"
            />
          ),
          render: renderStringOrBoolean,
        },
        {
          key: 'inlays',
          label: <TableRowHeader label="Inlays" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'inlaysInfo',
            setModal,
            'Inlays'
          ),
        },
        {
          key: 'dentures',
          label: (
            <TableRowHeader
              label="Tooth replacement"
              subtitle="false teeth & night guards"
              onClickInfo={() =>
                setModal({
                  fieldKey: 'dentures',
                  title: 'Tooth replacement',
                  tariff: 'header',
                  children:
                    'What counts as dentures:\n\n• dental protheses\n\n• crowns, veneers\n\n• bridges\n\n• implants and the necessary preparatory surgical measures to build up the jaw bone\n\n• Night guards and splints (e.g. grinding and snoring splints)\n\n• Functional analysis and functional therapy measures in connection with dental prostheses, dentures and splint therapy\n\n• the repair of dentures',
                })
              }
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'denturesInfo',
            setModal,
            'Dentures'
          ),
        },
        {
          key: 'orthodonticsChildren',
          label: <TableRowHeader label="Orthodontics for children" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'orthodonticsChildrenInfo',
            setModal,
            'Orthodontics for children'
          ),
        },
      ],
    },
    {
      id: 3,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <ActivityIcon size={20} noMargin /> Treatment and therapies
        </p>
      ),
      cells: [
        {
          key: 'preventiveCare',
          label: <TableRowHeader label="Preventive care treatments" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'preventiveCareInfo',
            setModal,
            'Preventive care treatments'
          ),
        },
        {
          key: 'therapeutic',
          label: (
            <TableRowHeader
              label="Therapeutic measures"
              subtitle="E.g. physiotherapy or speech therapy"
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'therapeuticInfo',
            setModal,
            'Therapeutic measures'
          ),
        },
        {
          key: 'mentalHealth',
          label: <TableRowHeader label="Mental health therapy" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'mentalHealthInfo',
            setModal,
            'Mental health therapy'
          ),
        },
        {
          key: 'radioTherapy',
          label: <TableRowHeader label="Radiation therapy" />,
          render: renderStringOrBoolean,
        },
        {
          key: 'natural',
          label: (
            <TableRowHeader
              label="Natural health treatments"
              subtitle="E.g. acupuncture, osteopathy, cupping"
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'naturalInfo',
            setModal,
            'Natural health treatments'
          ),
        },
        {
          key: 'medicalAids',
          label: <TableRowHeader label="Medical aids" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'medicalAidsInfo',
            setModal,
            'Medical aids'
          ),
        },
      ],
    },
    {
      id: 4,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <EyeVisionIcon size={20} noMargin /> Vision
        </p>
      ),
      cells: [
        {
          key: 'visionAids',
          label: (
            <TableRowHeader
              label="Vision aids"
              subtitle="E.g. glasses or contact lenses"
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'visionAidsInfo',
            setModal,
            'Vision aids'
          ),
        },
        {
          key: 'refractiveSurgery',
          label: (
            <TableRowHeader
              label="Refractive eye surgery"
              subtitle="E.g. LASIK"
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'refractiveSurgeryInfo',
            setModal,
            'Refractive eye surgery'
          ),
        },
      ],
    },
    {
      id: 5,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <DependentsFamilyIcon size={20} noMargin /> Pregnancy and childbirth
        </p>
      ),
      cells: [
        {
          key: 'pregnancy',
          label: <TableRowHeader label="Pregnancy coverage" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'pregnancyInfo',
            setModal,
            'Pregnancy coverage'
          ),
        },
        {
          key: 'fertilityTreatment',
          label: <TableRowHeader label="Fertility treatment" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'fertilityTreatmentInfo',
            setModal,
            'Fertility treatment'
          ),
        },
        {
          key: 'midwife',
          label: (
            <TableRowHeader
              label="Midwife support"
              subtitle="Before and after giving birth"
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'midwifeInfo',
            setModal,
            'Midwife support'
          ),
        },
        {
          key: 'nonMedicalPregnancyServices',
          label: (
            <TableRowHeader
              label="Non-medical pregnancy services"
              subtitle="E.g. pre-natal yoga, doula"
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'nonMedicalPregnancyServicesInfo',
            setModal,
            'Non-medical pregnancy services'
          ),
        },
      ],
    },
    {
      id: 6,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <HospitalBuildingIcon size={20} noMargin /> Hospital
        </p>
      ),
      cells: [
        {
          key: 'boardAndRoom',
          label: <TableRowHeader label="Board and room" />,
          render: renderStringOrBoolean,
        },
        {
          key: 'privateHospitals',
          label: <TableRowHeader label="Private hospitals" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'privateHospitalsInfo',
            setModal,
            'Private hospitals'
          ),
        },
        {
          key: 'headPhysician',
          label: <TableRowHeader label="Treatment by a head physician" />,
          render: withInfoModal(
            renderStringOrBoolean,
            'headPhysicianInfo',
            setModal,
            'Treatment by a head physician'
          ),
        },
      ],
    },
    {
      id: 7,
      label: (
        <p className="d-flex ai-center p-h4 c-gap8">
          <GlobeNetworkEarthIcon size={20} noMargin /> Outside of Germany
        </p>
      ),
      cells: [
        {
          key: 'worldwideCover',
          label: (
            <TableRowHeader
              label="Worldwide coverage"
              subtitle="outside of Europe up to 1 month"
            />
          ),
          render: withInfoModal(
            renderBoolean,
            'worldwideCoverInfo',
            setModal,
            'Worldwide coverage'
          ),
        },
        {
          key: 'euWideCover',
          label: (
            <TableRowHeader
              label="EU-wide coverage"
              subtitle="Including EEA"
              onClickInfo={() =>
                setModal({
                  fieldKey: 'euWideCover',
                  tariff: 'header',
                  title: 'EU-wide coverage',
                  children:
                    'Up to what is covered in Germany, more than this with pre-approval',
                })
              }
            />
          ),
          render: withInfoModal(
            renderStringOrBoolean,
            'euWideCoverInfo',
            setModal,
            'EU-wide coverage'
          ),
        },
        {
          key: 'repatriation',
          label: (
            <TableRowHeader
              label="Repatriation costs"
              onClickInfo={() =>
                setModal({
                  fieldKey: 'repatriation',
                  tariff: 'header',
                  title: 'Repatriation coverage',
                  children:
                    'Covers repatriation to Germany from another country.',
                })
              }
            />
          ),
          render: renderStringOrBoolean,
        },
      ],
    },
  ];
  return headers;
};

export const getTableData = ({
  eligibleForShortTerm,
  birthYear,
  publicHealthPriceString,
  sickDayPayout,
  shortTermPrice,
  standardPrice,
  plusPrice,
  premiumPrice,
  employmentStatus,
  shortTermDeductible,
  longTermDeductible,
}: {
  eligibleForShortTerm?: boolean;
  birthYear: number;
  publicHealthPriceString?: string;
  sickDayPayout: number;
  shortTermPrice: number;
  standardPrice: number;
  plusPrice?: number;
  premiumPrice: number;
  employmentStatus: EmploymentStatus;
  shortTermDeductible: string;
  longTermDeductible: string;
}): Array<TableData> => [
  ...(eligibleForShortTerm
    ? [
        {
          id: 0,
          employmentStatus,
          birthYear,
          sickDayPayout,
          name: 'Short-term',
          tariff: 'HiMedicalPlus' as Tariff,
          tariffKey: 'HiMedicalPlus' as Tariff,
          cashback: false,
          deductible: shortTermDeductible,
          deductibleInfo: deductibleInfoMapping.HiMedicalPlus,
          dependents: 'Extra cost',
          dependentsInfo: `The cost to add a non-working partner or child to your private health insurance depends on their age, health status and coverage.\n\n For each child the additional cost can range from approximately ≈ ${englishFormattedEuroCurrency(
            90,
            0
          )} - ${englishFormattedEuroCurrency(
            220,
            true
          )}/month and from ≈ ${englishFormattedEuroCurrency(
            180,
            true
          )} - ${englishFormattedEuroCurrency(
            700,
            true
          )}/month for adults.\n\n As an employee your employer will contribute up to 50% towards the cost of private health insurance for non-working dependents (spouse, legal partner or children).`,
          contribution: shortTermPrice,

          generalDoctor: '100%',
          specialist: '100%',
          medication: '100%',
          medicationInfo:
            'Medicines not included:\n\n• contraceptives,\n\n• nutraceutical products for the elderly,\n\n• nutriments and dietary supplements except for the aforementioned nutrients with medicinal properties,\n\n• dietetic products,\n\n• hormone preparations for anti-aging measures,\n\n• hair restorers,\n\n• potency-enhancing preparations,\n\n• cosmetics,\n\n• disinfectants,\n\n• bath additives and\n\n• comparable “lifestyle products”.',
          vaccination: '100%',
          vaccinationInfo:
            'Vaccinations [recommended by Robert-Koch-Institute](https://www.rki.de/DE/Content/Kommissionen/STIKO/Empfehlungen/Aktuelles/Impfkalender.pdf) (not including vaccinations that are work or travel-related). The Covid-19 vaccine is covered.',
          transports: '100%',
          transportsInfo:
            'Emergency transportation to a hospital. Non-hospital transportation is only covered for dialysis or chemotherapy in the event of an emergency.',
          sickPayCoverage: 'Up to 100% of net income',
          digitalHealthApps: `${englishFormattedEuroCurrency(
            120,
            true
          )} per year`,
          nonMedicalPrescriptions: false,
          purelyCosmeticProcedures: false,

          annualCoverage: `${englishFormattedEuroCurrency(1_500, true)}`,
          dentalCheckups: '100%',
          dentalCleanings: {
            value: `${englishFormattedEuroCurrency(100, true)} per year`,
          },
          dentist: '100%',
          inlays: '100%',
          dentures: '100%',
          orthodonticsChildren: '100%',
          orthodonticsChildrenInfo:
            '100% covered (up to the annual limit) if the treatment is started before the age of 18.',

          visionAids: `${englishFormattedEuroCurrency(
            250,
            true
          )} every 2 years`,
          visionAidsInfo:
            'You can claim every two years or if your eyesight worsens by a power of 0.5 within a year',
          refractiveSurgery: `${englishFormattedEuroCurrency(
            750,
            true
          )} per eye`,

          preventiveCare: '100%',
          preventiveCareInfo: 'Only for children up to the age of 18.',
          therapeutic: `Up to ${englishFormattedEuroCurrency(1_500, true)}`,
          therapeuticInfo: `Up to ${englishFormattedEuroCurrency(
            1_500,
            true
          )} per year. Higher limits apply for accidents or serious illnesses.`,
          mentalHealth: '100%',
          radioTherapy: '100%',
          natural: '100%',
          medicalAids: '75%-100%',
          medicalAidsInfo: `Aids are technical means and body replacements that compensate for or alleviate disabilities, the consequences of illness or accidents (e.g. bandages, insoles, prostheses).\n\n Dialysis machines are also reimbursed.\n\nExpenses above ${englishFormattedEuroCurrency(
            350,
            0
          )} must be pre-approved (otherwise, we cover up to 75%).\n\n \n\n**100% coverage for medical aids:**\n\n• which directly alleviate or compensate for disabilities or the consequences of illnesses or accidents (e.g. invalid carriages, prostheses),\n\n• if the insured person needs them for therapeutic and diagnostic purposes (e.g. blood pressure monitors), or\n\n• in order to stay alive (life-saving medical aids such as breathing aids).\n\n• provision of instructions on how to use the medical aids and for their maintenance and repair.\n\n \n\n**Limits apply to the following:**\n\n• ${englishFormattedEuroCurrency(
            250,
            0
          )} per calendar year for each insured person for orthopaedic shoes or the orthopaedic adaptation of shoes or insoles.\n\n• The insured person can receive up to a total of ${englishFormattedEuroCurrency(
            1500,
            0
          )} for a hearing aid for each ear throughout the period when the insurance is in force. If the hearing loss can only be corrected through the use of hearing implants, we will reimburse an overall amount of up to € 4,000 per ear for this care.\n\n \n\n**The following are not covered:**\n\n• repair of orthopedic footwear.\n\n• medical aids for which compulsory care insurance has to provide reimbursement,\n\n• medical aids which are part of fitness/wellness and/or recreational facilities,\n\n• everyday personal effects and hygiene products (e.g. medical thermometers, anti-allergy bedding).`,

          pregnancy: '100%',
          fertilityTreatment: '100%',
          midwife: '100%',
          nonMedicalPregnancyServices: false,

          boardAndRoom: 'Private room (1 bed)',
          privateHospitals: true,
          privateHospitalsInfo:
            'Up to 100% of the cost billed by a public hospital in Germany. No limit for emergency treatments.',
          headPhysician: '100%',
          euWideCover: true,
          euWideCoverInfo:
            'Up to what is covered in Germany, more than this with pre-approval',
          repatriation: true,
          worldwideCover: true,
          worldwideCoverInfo:
            'Up to what is covered in Germany, more with pre-approval.\n\nUp to 12 weeks in any country where you have citizenship.\n\nUp to 4 weeks in every other country.',
        },
      ]
    : []),
  {
    id: 1,
    employmentStatus,
    birthYear,
    sickDayPayout,
    name: tariffNameMapping.NKSelectS,
    tariff: 'NKSelectS',
    tariffKey: 'NKSelectS',
    cashback: 'NKSelectS',
    deductible: longTermDeductible,
    deductibleInfo: deductibleInfoMapping.NKSelectS,
    dependents: 'Extra cost',
    dependentsInfo: `The cost to add a non-working partner or child to your private health insurance depends on their age, health status, and coverage. \n\n For each child the additional cost can range from approximately ≈ ${englishFormattedEuroCurrency(
      90,
      true
    )}-${englishFormattedEuroCurrency(
      220,
      true
    )} per month and from approximately ${englishFormattedEuroCurrency(
      180,
      true
    )}-${englishFormattedEuroCurrency(
      700,
      true
    )} per month for adults. \n\n As an employee your employer will contribute up to 50% towards the cost of private health insurance for non-working dependents (spouse, legal partner or children).`,
    contribution: standardPrice,

    generalDoctor: '100%',
    specialist: '75%-100%',
    specialistInfo:
      '100% after consulting your general practitioner or in case of an emergency; otherwise 75% when seeing a specialist without a referral from a general doctor.',
    medication: '80%-100%',
    medicationInfo: `80% for the cost of medication  up to ${englishFormattedEuroCurrency(
      4000,
      true
    )} year. Medication costs beyond ${englishFormattedEuroCurrency(
      4000,
      true
    )} are covered 100%.\n\n100% for the cost of generic drugs. A generic drug is a pharmaceutical drug that contains the same chemical substance as a drug that was originally protected by chemical patents.`,
    vaccination: '100%',
    vaccinationInfo:
      'Vaccinations [recommended by Robert-Koch-Institute](https://www.rki.de/DE/Content/Kommissionen/STIKO/Empfehlungen/Aktuelles/Impfkalender.pdf). This also includes the Covid-19 vaccine. Vaccinations required for work are not covered if an employer is obligated to pay for them.',
    transports: '100%',
    transportsInfo:
      'Transportation to and from the nearest suitable doctor or hospital in case of: \n\n• Emergencies \n\n• Incapacity to walk \n\n• Dialysis \n\n• Deep radiation therapy \n\n• Chemotherapy \n\n• Outpatient operations on the day of the operation',
    sickPayCoverage: 'Up to 100% of net income',
    digitalHealthApps: '80%-100%',
    digitalHealthAppsInfo:
      'Certified apps are covered 100%, and 80% for non-certified apps up to €1,600 with pre-approval. \n\n [List of certified apps](https://diga.bfarm.de/de/verzeichnis)',
    nonMedicalPrescriptions: false,
    purelyCosmeticProcedures: false,

    annualCoverage: `Up to ${englishFormattedEuroCurrency(4000, true)}`,
    annualCoverageInfo: `Coverage limits apply during the first 5 years of coverage: \n\n ${englishFormattedEuroCurrency(
      500,
      true
    )} until 31.12.${currentYear} \n\n ${englishFormattedEuroCurrency(
      1000,
      true
    )} until 31.12.${currentYear + 1} \n\n ${englishFormattedEuroCurrency(
      1500,
      true
    )} until 31.12.${currentYear + 2} \n\n ${englishFormattedEuroCurrency(
      2000,
      true
    )} until 31.12.${currentYear + 3} \n\n ${englishFormattedEuroCurrency(
      4000,
      true
    )} until 31.12.${currentYear + 4} \n\n  ${englishFormattedEuroCurrency(
      4000,
      true
    )} per year after that time.`,
    dentalCheckups: '100%',
    dentalCleanings: {
      value: '1 time per year',
    },
    dentist: '100%',
    inlays: '70%',
    dentures: '70%',
    denturesInfo: `For implants, a cost plan must be provided, otherwise only 50% is covered. \n\n For other treatments, a cost plan is required for anything exceeding ${englishFormattedEuroCurrency(
      2500,
      true
    )}. Otherwise, anything above ${englishFormattedEuroCurrency(
      2500,
      true
    )} will only be covered at 50%.`,
    orthodonticsChildren: '70%',
    orthodonticsChildrenInfo: 'Pre-approval required.',

    preventiveCare: '100%',
    preventiveCareInfo:
      'As long as they are recommended by the German government. No age restrictions apply.',
    therapeutic: '80%',

    therapeuticInfo: `80% coverage up to ${englishFormattedEuroCurrency(
      4000,
      true
    )} per year for remedies listed below. Costs exceeding ${englishFormattedEuroCurrency(
      4000,
      true
    )} are covered 100%. \n\nThe following remedies are covered up to the amount listed in the policy:\n\n• Physical therapy/movement-based exercises\n\n• Massages\n\n• Physiotherapeutic palliative care packs, hydrotherapy, baths \n\n• Inhalations \n\n• Cold treatment and heat treatment \n\n• Electrotherapy \n\n• Light therapy \n\n• Speech therapy \n\n• Occupational therapy \n\n• Podiatry\n\n• Nutritional therapy\n\n• Birth preparation/pregnancy gymnastics and postnatal gymnastics\n\n• Rehabilitation sport/functional training in groups`,
    mentalHealth: '70%',
    mentalHealthInfo: 'Unlimited number of sessions per year',
    radioTherapy: '100%',
    natural: false,
    medicalAids: '80%',
    medicalAidsInfo: `Aids are technical means and body replacements that compensate for or alleviate disabilities, the consequences of illness or accidents (e.g. bandages, insoles, prostheses) \n\n Dialysis machines are also reimbursed. \n\nAids costs beyond ${englishFormattedEuroCurrency(
      4000,
      true
    )} are covered 100%.`,

    visionAids: `${englishFormattedEuroCurrency(150, true)} every 2 years`,
    visionAidsInfo:
      'You can claim every two years or if your eyesight worsens by a power of 0.5 within a year',
    refractiveSurgery: false,

    pregnancy: '100%',
    fertilityTreatment: false,
    midwife: '100%',
    nonMedicalPregnancyServices: false,

    boardAndRoom: 'Shared room',
    privateHospitals: true,
    privateHospitalsInfo:
      'Up to 150% of the cost billed by a public hospital in Germany. No limit for emergency treatments.',
    headPhysician: false,

    euWideCover: true,
    euWideCoverInfo:
      'Up to what would be covered in Germany (more in case of an emergency or with pre-approval)',
    repatriation: 'Not covered',
    worldwideCover: true,
    worldwideCoverInfo:
      'Up to 1 month according to what would be covered in Germany (more in case of an emergency or with pre-approval)',
  },
  {
    id: 2,
    employmentStatus,
    birthYear,
    sickDayPayout,
    name: tariffNameMapping.NKSelectL,
    tariff: 'NKSelectL',
    tariffKey: 'NKSelectL',
    cashback: 'NKSelectL',
    deductible: longTermDeductible,
    deductibleInfo: deductibleInfoMapping.NKSelectL,
    dependents: 'Extra cost',
    dependentsInfo: `The cost to add a non-working partner or child to your private health insurance depends on their age, health status, and coverage. \n\n For each child the additional cost can range from approximately ≈ ${englishFormattedEuroCurrency(
      90,
      true
    )}-${englishFormattedEuroCurrency(
      220,
      true
    )} per month and from approximately ${englishFormattedEuroCurrency(
      180,
      true
    )}-${englishFormattedEuroCurrency(
      700,
      true
    )} per month for adults. \n\n As an employee your employer will contribute up to 50% towards the cost of private health insurance for non-working dependents (spouse, legal partner or children).`,
    contribution: plusPrice,

    generalDoctor: '100%',
    specialist: '100%',
    specialistInfo:
      'We recommend consulting your general practitioner first to make sure your visit to the specialist is covered appropriately.',
    medication: '80%-100%',
    medicationInfo: `80% for the cost of medication  up to €2,000 year. Medication costs beyond €2000 are covered 100%.\n\n100% for the cost of generic drugs. A generic drug is a pharmaceutical drug that contains the same chemical substance as a drug that was originally protected by chemical patents.`,
    vaccination: '100%',
    vaccinationInfo:
      'Vaccinations [recommended by Robert-Koch-Institute](https://www.rki.de/DE/Content/Kommissionen/STIKO/Empfehlungen/Aktuelles/Impfkalender.pdf). This also includes the Covid-19 vaccine. Vaccinations required for work are not covered if an employer is obligated to pay for them.',
    transports: '100%',
    transportsInfo:
      'Transportation to and from the nearest suitable doctor or hospital in case of: \n\n• Emergencies \n\n• Incapacity to walk \n\n• Dialysis \n\n• Deep radiation therapy \n\n• Chemotherapy \n\n• Outpatient operations on the day of the operation',
    sickPayCoverage: 'Up to 100% of net income',
    digitalHealthApps: '80%-100%',
    digitalHealthAppsInfo:
      'Certified apps are covered 100%, and 80% for non-certified apps up to €1,600 with pre-approval. \n\n [List of certified apps](https://diga.bfarm.de/de/verzeichnis)',
    nonMedicalPrescriptions: false,
    purelyCosmeticProcedures: false,

    annualCoverage: 'Unlimited',
    annualCoverageInfo: `Coverage limits apply during the first 5 years of coverage: \n\n ${englishFormattedEuroCurrency(
      1000,
      true
    )} until 31.12.${currentYear} \n\n ${englishFormattedEuroCurrency(
      2000,
      true
    )} until 31.12.${currentYear + 1} \n\n ${englishFormattedEuroCurrency(
      3000,
      true
    )} until 31.12.${currentYear + 2} \n\n ${englishFormattedEuroCurrency(
      4000,
      true
    )} until 31.12.${currentYear + 3} \n\n  No limits after that time.`,
    dentalCheckups: '100%',
    dentalCleanings: {
      value: '2 times per year',
    },
    dentist: '100%',
    inlays: '80%',
    dentures: '80%',
    denturesInfo: `For implants, a cost plan must be provided, otherwise only 50% is covered. \n\n For other treatments, a cost plan is required for anything exceeding ${englishFormattedEuroCurrency(
      2500,
      true
    )}. Otherwise, anything above ${englishFormattedEuroCurrency(
      2500,
      true
    )} will only be covered at 50%.`,
    orthodonticsChildren: '80%',
    orthodonticsChildrenInfo: 'Pre-approval required.',

    preventiveCare: '100%',
    therapeutic: '80%',
    therapeuticInfo: `80% coverage up to ${englishFormattedEuroCurrency(
      2000,
      true
    )} per year for remedies listed below. Costs exceeding ${englishFormattedEuroCurrency(
      2000,
      true
    )} are covered 100%. \n\nThe following remedies are covered up to the amount listed in the policy:\n\n• Physical therapy/movement-based exercises\n\n• Massages\n\n• Physiotherapeutic palliative care packs, hydrotherapy, baths \n\n• Inhalations \n\n• Cold treatment and heat treatment \n\n• Electrotherapy \n\n• Light therapy \n\n• Speech therapy \n\n• Occupational therapy \n\n• Podiatry\n\n• Nutritional therapy\n\n• Birth preparation/pregnancy gymnastics and postnatal gymnastics\n\n• Rehabilitation sport/functional training in groups`,
    mentalHealth: '80%',
    mentalHealthInfo: 'Unlimited number of sessions per year',
    radioTherapy: '100%',
    natural: '80%',
    naturalInfo: 'Up to a total of €1200 per year',
    medicalAids: '80%',
    medicalAidsInfo: `Aids are technical means and body replacements that compensate for or alleviate disabilities, the consequences of illness or accidents (e.g. bandages, insoles, prostheses) \n\n Dialysis machines are also reimbursed. \n\nAids costs beyond ${englishFormattedEuroCurrency(
      2000,
      true
    )} are covered 100%.`,

    visionAids: `${englishFormattedEuroCurrency(300, true)} every 2 years`,
    visionAidsInfo:
      'You can claim every two years or if your eyesight worsens by a power of 0.5 within a year',
    refractiveSurgery: englishFormattedEuroCurrency(3000, true),

    pregnancy: '100%',
    fertilityTreatment: '80%',
    fertilityTreatmentInfo:
      'Some conditions apply:\n\n• The insured person has to have been insured under this tariff for at least 24 months.\n\n• The insured person is no more than 39 years old at the time of the treatment.\n\n• The treatment is carried out on married couples who are living together.\n\n• Only the egg cells and sperm cells of the couple may be used.\n\n• The treatment is in accordance with German law.',
    midwife: '100%',
    nonMedicalPregnancyServices: false,

    boardAndRoom: 'Private room (2 bed)',
    privateHospitals: true,
    privateHospitalsInfo:
      'Up to 150% of the cost billed by a public hospital in Germany. No limit for emergency treatments.',
    headPhysician: '100%',
    headPhysicianInfo:
      'Get the best treatment when you’re in the hospital. This allows you to get treated by the head physician or specialist of your choice. Without this, you will still get standard medical treatment and are fully covered for hospital stays, but have to rely on the doctor that’s assigned to you.\n\nIt’s especially useful if you’re in the hospital for routine or non-life-threatening treatments and want to be sure you will be treated by the most experienced doctor available.',

    euWideCover: true,
    euWideCoverInfo:
      'Up to what would be covered in Germany (more in case of an emergency or with pre-approval)',
    repatriation: true,
    worldwideCover: true,
    worldwideCoverInfo:
      'Up to 1 month according to what would be covered in Germany (more in case of an emergency or with pre-approval)',
  },
  {
    id: 3,
    employmentStatus,
    birthYear,
    sickDayPayout,
    name: tariffNameMapping.NKSelectXL,
    tariff: 'NKSelectXL',
    tariffKey: 'NKSelectXL',
    cashback: 'NKSelectXL',
    deductible: longTermDeductible,
    deductibleInfo: deductibleInfoMapping.NKSelectXL,
    dependents: 'Extra cost',
    dependentsInfo: `The cost to add a non-working partner or child to your private health insurance depends on their age, health status and coverage. \n\n For each child the additional cost can range from approximately ≈ ${englishFormattedEuroCurrency(
      90,
      0
    )}-${englishFormattedEuroCurrency(
      220,
      true
    )}/month and from ≈ ${englishFormattedEuroCurrency(
      180,
      true
    )}-${englishFormattedEuroCurrency(
      700,
      true
    )}/month for adults. \n\n As an employee your employer will contribute up to 50% towards the cost of private health insurance for non-working dependents (spouse, legal partner or children).`,
    contribution: premiumPrice,
    generalDoctor: '100%',
    specialist: '100%',
    specialistInfo:
      'We recommend consulting a general doctor first in any case to make sure your visit to the specialist is covered appropriately',
    medication: '100%',
    vaccination: '100%',
    vaccinationInfo:
      'Vaccinations [recommended by Robert-Koch-Institute](https://www.rki.de/DE/Content/Kommissionen/STIKO/Empfehlungen/Aktuelles/Impfkalender.pdf). This also includes the Covid-19 vaccine. Vaccinations required for work are not covered if an employer is obligated to pay for them.',
    transports: '100%',
    transportsInfo:
      'Transportation to and from the nearest suitable doctor or hospital in case of: \n\n• Emergencies \n\n• Incapacity to walk \n\n• Dialysis \n\n• Deep radiation therapy \n\n• Chemotherapy \n\n• Outpatient operations on the day of the operation',
    sickPayCoverage: 'Up to 100% of net income',
    digitalHealthApps: '80%-100%',
    digitalHealthAppsInfo:
      'Certified apps are covered 100%, and 80% for non-certified apps up to €1,600 with pre-approval.\n\n [List of certified apps](https://diga.bfarm.de/de/verzeichnis)',
    nonMedicalPrescriptions: false,
    purelyCosmeticProcedures: false,

    annualCoverage: 'Unlimited',
    annualCoverageInfo: `Different coverage limits apply during the first years of coverage: \n\n ${englishFormattedEuroCurrency(
      1500,
      true
    )} until 31.12.${currentYear} \n\n ${englishFormattedEuroCurrency(
      3000,
      true
    )} until 31.12.${currentYear + 1} \n\n ${englishFormattedEuroCurrency(
      4500,
      true
    )} until 31.12.${currentYear + 2} \n\n ${englishFormattedEuroCurrency(
      6000,
      true
    )} until 31.12.${currentYear + 3} \n\n No limits after that time.`,
    dentalCheckups: '100%',
    dentalCleanings: {
      value: 'Unlimited',
    },
    dentist: '100%',
    inlays: '90%',
    dentures: '90%',
    denturesInfo: `For implants, a cost plan must be provided, otherwise only 50% is covered. \n\n For other treatments, a cost plan is required for anything exceeding ${englishFormattedEuroCurrency(
      2500,
      true
    )}. Otherwise, anything above ${englishFormattedEuroCurrency(
      2500,
      true
    )} will only be covered at 50%.`,
    orthodonticsChildren: '90%',
    orthodonticsChildrenInfo: 'Pre-approval required.',

    preventiveCare: '100%',
    therapeutic: '100%',
    therapeuticInfo:
      'The following remedies are covered up to the amount listed in the policy:\n\n• Physical therapy/movement-based exercises\n\n• Massages\n\n• Physiotherapeutic palliative care packs, hydrotherapy, baths \n\n• Inhalations \n\n• Cold treatment and heat treatment \n\n• Electrotherapy \n\n• Light therapy \n\n• Speech therapy \n\n• Occupational therapy \n\n• Podiatry\n\n• Nutritional therapy\n\n• Birth preparation/pregnancy gymnastics and postnatal gymnastics\n\n• Rehabilitation sport/functional training in groups',
    mentalHealth: '90%',
    mentalHealthInfo: 'Unlimited number of sessions per year',
    radioTherapy: '100%',
    natural: '80%',
    naturalInfo: `Up to a total of ${englishFormattedEuroCurrency(
      2400,
      true
    )} per year`,
    medicalAids: '100%',
    medicalAidsInfo:
      'Aids are technical means and body replacements that compensate for or alleviate disabilities, the consequences of illness or accidents (e.g. bandages, insoles, prostheses). \n\n Dialysis machines and guide dogs are also reimbursed.',

    visionAids: `${englishFormattedEuroCurrency(450, true)} every 2 years`,
    visionAidsInfo:
      'You can claim every two years or if your eyesight worsens by a power of 0.5 within a year',
    refractiveSurgery: englishFormattedEuroCurrency(5000, true),

    pregnancy: '100%',
    fertilityTreatment: '100%',
    fertilityTreatmentInfo:
      'Some conditions apply:\n\n• The insured person has to have been insured under this tariff for at least 24 months.\n\n• The insured person is no more than 39 years old at the time of the treatment.\n\n• The treatment is carried out on married couples who are living together.\n\n• Only the egg cells and sperm cells of the couple may be used.\n\n• The treatment is in accordance with German law.',
    midwife: '100%',
    nonMedicalPregnancyServices: false,

    boardAndRoom: 'Private room (1 bed)',
    privateHospitals: true,
    privateHospitalsInfo:
      'Up to 200% of the cost billed by a public hospital in Germany. No limit for emergency treatments.',
    headPhysician: '100%',
    headPhysicianInfo:
      'Get the best treatment when you’re in the hospital. This allows you to get treated by the head physician or specialist of your choice. Without this, you will still get standard medical treatment and are fully covered for hospital stays, but have to rely on the doctor that’s assigned to you.\n\nIt’s especially useful if you’re in the hospital for routine or non-life-threatening treatments and want to be sure you will be treated by the most experienced doctor available.',

    euWideCover: true,
    repatriation: true,
    worldwideCover: true,
    worldwideCoverInfo:
      'Up to 6 months according to what would be covered in Germany (more in case of an emergency or with pre-approval).',
  },
  {
    id: 4,
    birthYear,
    sickDayPayout,
    name: 'Public',
    tariffKey: 'Public',
    cashback: false,
    deductible: englishFormattedEuroCurrency(0, true),
    dependents: true,
    dependentsInfo:
      'No extra cost to add children and non-working legal partners',
    contribution: publicHealthPriceString,
    generalDoctor: '100%',
    specialist: '100%',
    medication: '90%',
    vaccination: '100%',
    vaccinationInfo:
      'Coverage for vaccinations [recommended by the STIKO](https://www.bundesgesundheitsministerium.de/en/topics/vaccinations.html).',
    transports: '100%',
    transportsInfo:
      'In the event of an emergency, ambulance, taxi or Uber to the closest suitable hospital or clinic. The return trip can be covered in some cases.\n\nNon-hospital transportation is only for dialysis or chemotherapy in the event of an emergency.',
    sickPayCoverage: 'Limited to €3,491',
    digitalHealthApps: 'Certified apps',
    digitalHealthAppsInfo:
      'Only certified apps are covered. [List of certified apps](https://diga.bfarm.de/de/verzeichnis)',
    nonMedicalPrescriptions: false,
    purelyCosmeticProcedures: false,

    annualCoverage: 'Limited',
    annualCoverageInfo:
      'Reduced coverage for tooth replacement, root canal treatments, gum treatments, and other limitations can lead to high out-of-pocket payments.',
    dentalCheckups: '100%',
    dentalCleanings: {
      value: false,
      description: 'Prophylaxis is not included',
    },
    dentist: '100%',
    inlays: '30%',
    dentures: '50%',
    orthodonticsChildren: '80%-100%',
    preventiveCare: 'Partially covered',
    preventiveCareInfo:
      'Most preventive care treatments are only available after the age of 35 (full body checkup, skin cancer screening.\n\nThere are no age restrictions for annual gynecologist check-ups.\n\nHere is a [complete list.](https://www.krankenkassen.de/gesetzliche-krankenkassen/leistungen-gesetzliche-krankenkassen/gesetzlich-vorgeschriebene-leistungen/gesetzliche-krankenkassen-Vorsorgeuntersuchungen/)',
    therapeutic: '90%',
    therapeuticInfo:
      'The amount of prescribed sessions for a condition (e.g. physio therapy) is usually limited to a handful of sessions for most conditions.',
    mentalHealth: '100%',
    mentalHealthInfo:
      'Pre-approval required. Finding a therapist may be difficult',
    radioTherapy: '100%',
    natural: false,
    medicalAids: '75%-90%',
    medicalAidsInfo: 'Medical aids: 90% \n\n Hearing aids: 75%, up to €780',
    visionAids: false,
    refractiveSurgery: false,

    pregnancy: '100%',
    fertilityTreatment: 'Rarely',
    fertilityTreatmentInfo:
      'In some cases partially covered for married couples (Age 25-40).\n\nPre-approval required.',
    midwife: '100%',
    midwifeInfo:
      'Can be difficult to find a midwife on public health insurance in large cities.',
    nonMedicalPregnancyServices: 'Some services',
    nonMedicalPregnancyServicesInfo:
      'Coverage depends on the provider. Some providers cover birth preparation classes for the partner (up to a limit), specific prenatal and postnatal exercise classes, and doulas when prescribed by a doctor.',

    boardAndRoom: 'Shared room',
    privateHospitals: false,
    headPhysician: false,
    euWideCover: 'Partially covered',
    repatriation: false,
    worldwideCover: false,
  },
];
