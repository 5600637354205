import { app } from '@getpopsure/private-constants';
import * as Sentry from '@sentry/react';
import {
  flushGenericQuestionnaire,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import lz from 'lz-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GenericQuestionnaireState } from 'reducers/genericQuestionnaire';

import { validatePath } from './validatePath.utils';

/**
 *
 * Stand alone component for seeding questionnaires.
 * Will read the search query params to populate a questionnaire with the provided values.
 * Note: If there are previously persisted answers, these will be removed.
 *
 *
 */
export const SeedQuestionnaire = () => {
  // collect the current path and values

  const path = useQueryParamValue('p');
  const valuesParam = useQueryParamValue('v');
  const type = useQueryParamValue('t');
  const values = lz.decompressFromEncodedURIComponent(valuesParam ?? '');

  const parsedValues = JSON.parse(values ?? '');

  const dispatch = useDispatch();

  const pathValidationResult = validatePath(path);

  useEffect(() => {
    if (!type) {
      window.location.href = app.base;
      return;
    }

    // reset any existing questionnaire answers for the vertical
    dispatch(
      flushGenericQuestionnaire(type as keyof GenericQuestionnaireState)
    );

    // populate the questionnaire with the newly provided questionnaire answers
    dispatch(
      storeGenericQuestionnaireAnswer(
        type as keyof GenericQuestionnaireState,
        parsedValues
      )
    );

    const timeoutId = setTimeout(() => {
      if (!path) {
        window.location.href = app.base;
      }
      if (pathValidationResult.isValid) {
        window.location.href = pathValidationResult.validPath;
      } else {
        Sentry.captureMessage(
          '[Seeding questionnaire] Invalid path provided for questionnaire seeding.'
        );
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  });

  if (!pathValidationResult.isValid)
    return (
      <ErrorWithAction
        title="Something went wrong."
        description="The link is invalid."
      />
    );
  return <LoadingSpinner />;
};
