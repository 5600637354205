import { trackPrivateHealthQuoteTableModalOpened } from '@getpopsure/analytics';
import {
  EmploymentStatus,
  getPriceBreakdown,
  tariffNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { ComparisonTable } from '@popsure/dirty-swan';
import { TableModal } from 'features/privateHealthPreSignup/components/TableModal';
import {
  Modal,
  ModalWithTracking,
  planOptions,
  TableData,
  Tariff,
  TariffsWithPublic,
} from 'features/privateHealthPreSignup/models';
import { getPublicHealthContribution } from 'features/privateHealthPreSignup/util';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useCallback, useMemo, useState } from 'react';

import { CashbackModal, ContributionModal } from './components/modals';
import { DeductibleAdjustmentModal } from './components/modals/DeductibleAdjustmentModal/DeductibleAdjustmentModal';
import styles from './Coverage.module.scss';
import {
  FreelancerDeductibleType,
  longTermDeductibleMapper,
  LongTermFreelancerDeductible,
  shortTermDeductibleMapper,
  ShortTermFreelancerDeductible,
} from './models';
import { getTableData, getTableHeaders } from './tableData';

export const Coverage = ({
  eligibleForShortTerm,
  birthYear,
  income,
  sickDayPayout,
  employmentStatus,
  showHeaderPrice = true,
  selectedPlan,
  setSelectedPlan,

  shortTermDeductible,
  longTermDeductible,
  setShortTermDeductible,
  setLongTermDeductible,
}: {
  eligibleForShortTerm?: boolean;
  birthYear: number;
  income: number;
  sickDayPayout: number;
  employmentStatus: EmploymentStatus;
  showHeaderPrice?: boolean;
  selectedPlan?: Tariff;
  setSelectedPlan: (tariff: Tariff) => void;

  // Freelancer deductible
  shortTermDeductible: ShortTermFreelancerDeductible;
  longTermDeductible: LongTermFreelancerDeductible;
  setShortTermDeductible: (deductible: ShortTermFreelancerDeductible) => void;
  setLongTermDeductible: (deductible: LongTermFreelancerDeductible) => void;
}) => {
  const isMobile = useMediaQuery('BELOW_MOBILE');

  const [modal, setModal] = useState<null | Modal>();

  // Deductible adjustment modal
  const [isDeductibleAdjustmentModalOpen, setIsDeductibleAdjustmentModalOpen] =
    useState(false);
  const [
    tariffForDeductibleAdjustmentModal,
    setTariffForDeductibleAdjustmentModal,
  ] = useState<Tariff>();

  const handleOpenModal = useCallback((newModal: ModalWithTracking) => {
    setModal(newModal);
    if (newModal) {
      trackPrivateHealthQuoteTableModalOpened({
        field_key: newModal.fieldKey,
        tariff_name: newModal.tariff,
        is_landing_page: false,
      });
    }
  }, []);

  const handleDeductibleAdjustmentModal = (tariff: Tariff) => {
    setIsDeductibleAdjustmentModalOpen(true);
    setTariffForDeductibleAdjustmentModal(tariff);
  };

  const publicHealthContribution = getPublicHealthContribution({
    birthYear,
    income,
    employmentStatus,
  });

  let publicHealthPriceString: string | undefined;

  if (publicHealthContribution) {
    const priceStringPrefix = employmentStatus === 'OTHER' ? '~' : '';
    publicHealthPriceString = `${priceStringPrefix}${englishFormattedEuroCurrency(
      Math.round(publicHealthContribution.total),
      true
    )}`;
  }

  const shortTermPrice = getPriceBreakdown({
    tariff: 'HiMedicalPlus',
    birthYear,
    sickDayPayout,
    employmentStatus,
    deductible: shortTermDeductible,
  }).contributions.own;

  const standardPrice = getPriceBreakdown({
    tariff: 'NKSelectS',
    birthYear,
    sickDayPayout,
    employmentStatus,
    deductible: longTermDeductible,
  }).contributions.own;

  const plusPrice = getPriceBreakdown({
    tariff: 'NKSelectL',
    birthYear,
    sickDayPayout,
    employmentStatus,
    deductible: longTermDeductible,
  }).contributions.own;

  const premiumPrice = getPriceBreakdown({
    tariff: 'NKSelectXL',
    birthYear,
    sickDayPayout,
    employmentStatus,
    deductible: longTermDeductible,
  }).contributions.own;

  const tableData: Array<TableData> = getTableData({
    eligibleForShortTerm,
    shortTermPrice,
    standardPrice,
    plusPrice,
    premiumPrice,
    publicHealthPriceString,
    sickDayPayout,
    birthYear,
    employmentStatus,
    shortTermDeductible: shortTermDeductibleMapper[shortTermDeductible],
    longTermDeductible: longTermDeductibleMapper[longTermDeductible],
  });

  const tableHeaders = useMemo(() => {
    const handleOpenContributionModal = (tariff: TariffsWithPublic) => {
      handleOpenModal({
        fieldKey: 'contribution',
        tariff,
        title: 'How is my price calculated?',
        children: (
          <ContributionModal
            priceData={
              tariff === 'Public'
                ? publicHealthContribution
                : getPriceBreakdown({
                    birthYear,
                    employmentStatus,
                    sickDayPayout,
                    tariff,
                  })
            }
          />
        ),
      });
    };

    const handleCashbackModal = (tariff: Tariff) =>
      handleOpenModal({
        fieldKey: 'cashback',
        tariff,
        title: `Cashback (${tariffNameMapping[tariff]})`,
        children: (
          <CashbackModal
            birthYear={birthYear}
            income={income || 0}
            tariff={tariff}
          />
        ),
      });

    return getTableHeaders({
      setModal,
      handleOpenContributionModal,
      showPrice: showHeaderPrice,
      setSelectedPlan,
      handleCashbackModal,
      selectedPlan,
      handleDeductibleAdjustmentModal,
    });
  }, [
    sickDayPayout,
    handleOpenModal,
    employmentStatus,
    income,
    publicHealthContribution,
    birthYear,
    showHeaderPrice,
    selectedPlan,
    setSelectedPlan,
  ]);

  const onCloseDeductibleAdjustmentModal = () =>
    setIsDeductibleAdjustmentModalOpen(false);

  const onConfirmDeductible = (deductible: FreelancerDeductibleType) => {
    const isShortTerm =
      tariffForDeductibleAdjustmentModal === 'HiMedical' ||
      tariffForDeductibleAdjustmentModal === 'HiMedicalPlus';

    const isLongTerm =
      tariffForDeductibleAdjustmentModal === 'NKSelectL' ||
      tariffForDeductibleAdjustmentModal === 'NKSelectS' ||
      tariffForDeductibleAdjustmentModal === 'NKSelectXL';

    if (isShortTerm) {
      setShortTermDeductible(deductible as ShortTermFreelancerDeductible);
    }

    if (isLongTerm) {
      setLongTermDeductible(deductible as LongTermFreelancerDeductible);
    }
  };

  return (
    <div className={`p-container ${styles.container}`}>
      {isDeductibleAdjustmentModalOpen && (
        <DeductibleAdjustmentModal
          isOpen={isDeductibleAdjustmentModalOpen}
          onClose={onCloseDeductibleAdjustmentModal}
          onConfirm={onConfirmDeductible}
          tariff={tariffForDeductibleAdjustmentModal}
          shortTermDeductible={shortTermDeductible}
          longTermDeductible={longTermDeductible}
          birthYear={birthYear}
          employmentStatus={employmentStatus}
          sickDayPayout={sickDayPayout}
        />
      )}
      <ComparisonTable
        headers={tableHeaders}
        data={tableData}
        hideDetails
        showDetailsCaption="What’s covered?"
        hideDetailsCaption="Hide details"
        growContent
        collapsibleSections
        hideScrollBarsMobile
        onSelectionChanged={(index) => {
          if (!isMobile) {
            return;
          }
          const maxIndex = planOptions.length - 1;
          return index > maxIndex
            ? setSelectedPlan(planOptions[maxIndex])
            : setSelectedPlan(planOptions[index]);
        }}
      />
      {modal && <TableModal modal={modal} setModal={setModal} />}
    </div>
  );
};
