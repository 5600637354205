type ValidPathResult =
  | {
      isValid: true;
      validPath: string;
    }
  | {
      isValid: false;
      validPath: null;
    };

export const validatePath = (path: string | null): ValidPathResult => {
  if (!path) {
    return { isValid: false, validPath: null };
  }

  const match = path.match(/^\/policies(\/?)(?:[a-z0-9_-]+\/?)*$/i);
  if (!match) {
    return { isValid: false, validPath: null };
  }

  return { isValid: true, validPath: path };
};
