import { Button, TableTrueFalse } from '@popsure/dirty-swan';
import {
  ModalWithTracking,
  TableData,
  Tariff,
  TariffsWithPublic,
  ValueWithDescription,
} from 'features/privateHealthPreSignup/models';

import {
  CashbackCell,
  CTACell,
  PriceCell,
  ValueCell,
} from '../components/cells';
import { MiniProgressBar } from '../components/MiniProgressBar/MiniProgressBar';
import { WithModalWrapper } from '../components/wrappers';

export const withInfoModal =
  <T extends TableData, Q>(
    renderer: (value: Q, row: T) => React.ReactNode,
    tableKey: keyof T,
    setModal: (modal: ModalWithTracking) => void,
    title?: string
  ) =>
  (value: Q, row: T) => {
    const { name, [tableKey]: children, tariffKey } = row;

    const onClick =
      children !== undefined
        ? () =>
            setModal({
              fieldKey: String(tableKey),
              tariff: tariffKey,
              title: title || name,
              children: children as React.ReactNode,
            })
        : undefined;

    if (!onClick) {
      return renderer(value, row);
    }

    return (
      <WithModalWrapper row={row} tableKey={tableKey} onClick={onClick}>
        {renderer(value, row)}
      </WithModalWrapper>
    );
  };

export const renderTableCTA =
  <T extends TableData>({
    selectedPlan,
    setSelectedPlan,
    showPrice,
  }: {
    selectedPlan?: Tariff;
    setSelectedPlan: (tariff: Tariff) => void;
    showPrice?: boolean;
  }) =>
  (name: string, row: T) => {
    const { tariff } = row;
    return (
      <CTACell
        name={name}
        row={row}
        showPrice={showPrice}
        selectedPlan={selectedPlan}
        onCTAClick={() => tariff && setSelectedPlan(tariff)}
      />
    );
  };

export const renderEmpty = () => () => <div className="mt32" />;

export const renderContribution =
  <T extends TableData>(
    handleOpenContributionModal: (tariff: TariffsWithPublic) => void
  ) =>
  (price: number | string, row: T) => {
    const { tariff } = row;

    if (price) {
      return (
        <PriceCell
          price={price}
          onInfoClick={() => handleOpenContributionModal(tariff ?? 'Public')}
        />
      );
    }

    return (
      <div>
        <h3 className="p-h1 p--serif tc-primary-500">€—</h3>
        <p className="p-p--small tc-primary-500">Get an estimate</p>
      </div>
    );
  };

export const renderSickPayCoverage = (value: string) => (
  <div>
    <ValueCell value={value} />
    <p className="p-p--small tc-grey-500">per month</p>
  </div>
);

export const renderBoolean = (value: boolean) => (
  <TableTrueFalse value={value} />
);

export const renderStringOrBooleanNoProgress = (
  value: string | boolean
): string | JSX.Element =>
  typeof value === 'string' ? value : renderBoolean(value);

export const renderStringOrBoolean = (
  value: string | boolean
): string | JSX.Element =>
  typeof value === 'string'
    ? renderStringWithProgress(value)
    : renderBoolean(value);

export const renderStringWithProgress = (
  value: string
): string | JSX.Element => {
  const progressLookup: Record<string, number> = {
    '30%': 1,
    '50%': 2,
    '70%': 3,
    '75%': 4,
    '80%': 4,
    '90%': 4,
    '75%-90%': 4,
    '75%-100%': 4,
    '80%-100%': 4,
    '100%': 5,
  };
  const progressBarValue = progressLookup[value];

  return (
    <div className="d-flex fd-column ai-center">
      {progressBarValue !== undefined && (
        <MiniProgressBar nFilledBars={progressBarValue} />
      )}
      {value}
    </div>
  );
};

export const renderCashback =
  <T extends TableData>(handleCashbackModal: (tariff: Tariff) => void) =>
  (tariff: Tariff | boolean, row: T) => {
    if (typeof tariff === 'boolean') return <TableTrueFalse value={tariff} />;
    const { birthYear, sickDayPayout } = row;
    return (
      <CashbackCell
        birthYear={birthYear}
        sickDayPayout={sickDayPayout}
        tariff={tariff}
        onClick={() => handleCashbackModal(tariff)}
      />
    );
  };

export const renderValueWithDescription = (item: ValueWithDescription) => (
  <ValueCell value={item.value} description={item.description} />
);

export const renderDeductibleAdjustment =
  (handleDeductibleAdjustmentModal: (tariff: Tariff) => void) =>
  (value: string, data: TableData) => {
    const { tariff, employmentStatus } = data;

    return (
      <div>
        <p className="p-p">{value}</p>
        {tariff && employmentStatus === 'SELF_EMPLOYED' && (
          <Button
            className="p-p--small tc-primary-500"
            onClick={() => handleDeductibleAdjustmentModal(tariff)}
            variant="filledWhite"
          >
            Adjust
          </Button>
        )}
      </div>
    );
  };
