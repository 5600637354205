import {
  ActiveTariff,
  EmploymentStatus,
  getPriceBreakdown,
} from '@getpopsure/private-health-insurance-pricing-engine';
import {
  BottomOrRegularModal,
  Button,
  SegmentedControl,
} from '@popsure/dirty-swan';
import { tariffLabelMapper } from 'features/privateHealthPreSignup/models';
import { useEffect, useState } from 'react';
import englishFormattedEuroCurrency from 'shared/util/priceFormatter';

import {
  FreelancerDeductibleType,
  longTermDeductibleMapper,
  LongTermFreelancerDeductible,
  longtTermDeductibleOptions,
  shortTermDeductibleMapper,
  shortTermDeductibleOptions,
  ShortTermFreelancerDeductible,
} from '../../../models';

interface Props {
  tariff?: ActiveTariff;
  shortTermDeductible: ShortTermFreelancerDeductible;
  longTermDeductible: LongTermFreelancerDeductible;

  birthYear: number;
  sickDayPayout: number;
  employmentStatus: EmploymentStatus;

  isOpen: boolean;
  onClose: () => void;
  onConfirm: (deductible: FreelancerDeductibleType) => void;
}

export const DeductibleAdjustmentModal = ({
  tariff = 'NKSelectS',
  shortTermDeductible,
  longTermDeductible,

  birthYear,
  sickDayPayout,
  employmentStatus,

  isOpen,
  onClose,
  onConfirm,
}: Props) => {
  const [shortTermDeductibleIndex, setShortTermDeductibleIndex] = useState(
    Object.keys(shortTermDeductibleMapper).indexOf(shortTermDeductible)
  );
  const [longTermDeductibleIndex, setLongTermDeductibleIndex] = useState(
    Object.keys(longTermDeductibleMapper).indexOf(longTermDeductible)
  );
  const [price, setPrice] = useState<number>();

  const isShortTerm = tariff === 'HiMedical' || tariff === 'HiMedicalPlus';

  const isLongTerm =
    tariff === 'NKSelectL' || tariff === 'NKSelectS' || tariff === 'NKSelectXL';

  useEffect(() => {
    const shortTermModalDeductible = Object.keys(shortTermDeductibleMapper)[
      shortTermDeductibleIndex
    ] as FreelancerDeductibleType;

    const longTermModalDeductible = Object.keys(longTermDeductibleMapper)[
      longTermDeductibleIndex
    ] as FreelancerDeductibleType;

    const calculatedPrice = getPriceBreakdown({
      tariff,
      birthYear,
      sickDayPayout,
      employmentStatus,
      deductible: isShortTerm
        ? shortTermModalDeductible
        : longTermModalDeductible,
    }).contributions.own;

    setPrice(calculatedPrice);
  }, [
    tariff,
    birthYear,
    sickDayPayout,
    employmentStatus,
    shortTermDeductibleIndex,
    longTermDeductibleIndex,
    isShortTerm,
  ]);

  const onConfirmDeductible = () => {
    if (isShortTerm) {
      const deductible = Object.keys(shortTermDeductibleMapper)[
        shortTermDeductibleIndex
      ] as FreelancerDeductibleType;
      onConfirm(deductible);
    }

    if (isLongTerm) {
      const deductible = Object.keys(longTermDeductibleMapper)[
        longTermDeductibleIndex
      ] as FreelancerDeductibleType;
      onConfirm(deductible);
    }

    onClose();
  };

  return (
    <BottomOrRegularModal
      className="wmx6"
      isOpen={isOpen}
      onClose={onClose}
      title="What is a deductible?"
    >
      <div className="px24 pb32">
        <p className="p-p">
          A deductible is the amount you need to pay for healthcare services out
          of pocket before your health insurance begins to pay. It resets every
          calendar year.
          <span className="fw-bold">
            You don’t have to pay a deductible for many preventative check-ups.
          </span>
        </p>

        {isShortTerm && (
          <>
            <SegmentedControl
              className="mt32"
              values={shortTermDeductibleOptions}
              selectedIndex={shortTermDeductibleIndex}
              onChange={(index) => {
                setShortTermDeductibleIndex(index);
              }}
            />
            {deductibleTextMapper[shortTermDeductible]}
          </>
        )}

        {isLongTerm && (
          <>
            <SegmentedControl
              className="mt32"
              values={longtTermDeductibleOptions}
              selectedIndex={longTermDeductibleIndex}
              onChange={(index) => {
                setLongTermDeductibleIndex(index);
              }}
            />
            {deductibleTextMapper[longTermDeductible]}
          </>
        )}

        {tariff && price && (
          <div className="fw-bold d-flex ai-center mt16 jc-center bg-grey-200 br8 py16 px16">
            <span className="p-p fw-bold">
              {tariffLabelMapper[tariff]} plan
            </span>
            <div className="ml16 p--serif">
              <span className="p-h2 tc-purple-500">
                {englishFormattedEuroCurrency(Math.round(price), 0)}
              </span>
              <span className="p-p--small tc-purple-500">/mo</span>
            </div>
          </div>
        )}

        <Button
          className="w100 mt32"
          type="button"
          variant="filledColor"
          onClick={onConfirmDeductible}
        >
          Confirm
        </Button>
      </div>
    </BottomOrRegularModal>
  );
};

const stUpTo500Text = (
  <p className="p-p mt32">
    For example, if you have a{' '}
    <span className="fw-bold">
      10% deductible up to {englishFormattedEuroCurrency(500, 0)}
    </span>{' '}
    and get healthcare bills for a total of{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(4_000, 0)}</span> in
    the calendar year, you will pay{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(400, 0)}</span>{' '}
    (10%) and the insurance will cover the rest — up to any coverage limits. The
    maximum deductible you’ll pay for a calendar year is €500.
  </p>
);

const stLt1200Text = (
  <p className="p-p mt32">
    For example, if you have a{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(1_200, 0)}</span>{' '}
    deductible and get healthcare bills for a total of{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(4_000, 0)}</span> in
    the calendar year, you will pay{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(1_200, 0)}</span>{' '}
    and the insurance will cover the rest — up to any coverage limits.
  </p>
);

const lt0Text = (
  <p className="p-p mt32">
    For example, if you have a{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(0, 0)}</span>{' '}
    deductible and get healthcare bills for a total of{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(4_000, 0)}</span> in
    the calendar year, the insurance will cover the bills fully — up to any
    coverage limits.
  </p>
);

const lt3000Text = (
  <p className="p-p mt32">
    For example, if you have a{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(3_000, 0)}</span>{' '}
    deductible and get healthcare bills for a total of{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(4_000, 0)}</span> in
    the calendar year, you will pay{' '}
    <span className="fw-bold">{englishFormattedEuroCurrency(3_000, 0)}</span>{' '}
    and the insurance will cover the rest — up to any coverage limits.
  </p>
);

const deductibleTextMapper: Record<
  FreelancerDeductibleType,
  React.ReactElement
> = {
  '500': stUpTo500Text,
  '0': lt0Text,
  '1200': stLt1200Text,
  '3000': lt3000Text,
};
